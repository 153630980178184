import { Button, Col, Form,   Modal, Row, } from "antd";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
    getSiteinfo,
  patchSiteinfo,
} from "../../host/Config";
import { UploadOutlined } from "@ant-design/icons";
import { message,  Upload } from "antd";
import { useStore } from "../../store/Store";
import { getBase64 } from "../../components/Objects";
import { InputDesc, InputText } from "../../utils/Inputs";


export default function Siteinfo() {
  const [form] = Form.useForm();
  const [sel_data, setSel_data] = useState(null);
  const [image_id, setimage_id] = useState(null);
  const [modal, setModal] = useState(false);
  const [edit, setedit] = useState(null);
  const [btnDis, setBtnDis] = useState(false);
  const [cookie, setCookie] = useCookies();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const setLoader = useStore((state) => state.setLoader);
 
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setBtnDis(true);
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setBtnDis(false);
      setimage_id(info.file.originFileObj);
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
      message.success(`${info.file.name} file uploaded successfully`);
    }
  };

  

 
  const getSiteinfoData = () => {
    getSiteinfo(cookie.token).then((res) => {
    form.setFieldsValue(res.data);
    setImageUrl(res.data.image);
      setLoader(false);
      setedit(res.data.id)
    });
  };
 
  useEffect(() => {
    getSiteinfoData();
  }, []);

  const onFinish = (event) => {
    setLoader(true);
    const formData = new FormData();
   
      
    formData.append("first_name", event.first_name);
    formData.append("last_name", event.last_name);
    formData.append("full_name", event.full_name);
    // formData.append("username", event.username);
    formData.append("work_place", event.work_place);
    formData.append("subject", event.subject);
    formData.append("email", event.email);
    formData.append("phone", event.phone);
    formData.append("website", event.website);
    formData.append("instagram", event.instagram);
    formData.append("telegram", event.telegram);
    formData.append("facebook", event.facebook);
    formData.append("youtube", event.youtube);
    formData.append("description", event.description);

    if (image_id) {
        formData.append("image", event.image.fileList[0].originFileObj);
      }
      patchSiteinfo(cookie.token, formData, edit)
        .then((res) => {
          message.success("Ma'lumot o'zgartirildi");
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          message.error("Ma'lumot o'zgartirilmadi");
        });
    
      
  };
 
 
  return (
    <div>
      <div className="adminBox">
       
            <Form
              form={form}
              name="basic"
              labelCol={{
                span: 24,
              }}
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFieldsChange={()=>{}}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <div className="admin_btns">
               
                <Button
                  disabled={btnDis}
                  style={{ marginLeft: "20px", backgroundColor: "#00a900" }}
                  type="primary"
                  htmlType="submit"
                >
                  {" "}
                  Saqlash
                </Button>
              </div>
              <Row style={{ paddingTop: "20px" }}>
              <Col style={{ padding: "0px 20px" }} lg={8} md={24}>
                  <InputText name={"first_name"} label={"Ism, sharifi"}/>
                </Col>
                <Col style={{ padding: "0px 20px" }} lg={8} md={24}>
                  <InputText name={"last_name"} label={"Familiya"}/>
                </Col>
                
                <Col style={{ padding: "0px 20px" }} lg={8} md={24}>
                  <InputText name={"work_place"} label={"Ish joyi va lavozimi"}/>
                </Col>
                <Col style={{ padding: "0px 20px" }} lg={8} md={24}>
                  <InputText name={"subject"} label={"Fan"}/>
                </Col>
             
                <Col style={{ padding: "0px 20px" }} lg={8} md={24}>
                  <InputText name={"email"} type="email" label={"Email"}/>
                </Col>
                <Col style={{ padding: "0px 20px" }} lg={8} md={24}>
                <InputText name={"phone"} type="tel" label={"Telefon"} />
                </Col>
                <Col style={{ padding: "0px 20px" }} lg={8} md={24}>
                  <InputText name={"website"} label={"Web sayt"}/>
                </Col>
            
                <Col style={{ padding: "0px 20px" }} lg={8} md={24}>
                  <InputText name={"instagram"}  label={"Instagram"}/>
                </Col>
                <Col style={{ padding: "0px 20px" }} lg={8} md={24}>
                <InputText name={"telegram"} type="tel" label={"Telegram"}/>
                </Col>
                <Col style={{ padding: "0px 20px" }} lg={8} md={24}>
                  <InputText name={"facebook"} label={"Facebook"}/>
                </Col>
             
                <Col style={{ padding: "0px 20px" }} lg={16} md={24}>
                  <InputText name={"youtube"}  label={"Youtube"}/>
                </Col>
                <Col style={{ padding: "0px 20px" }} lg={8} md={24}>
                <Form.Item
      label={'Rasm'}
      name={"image"}
      
      rules={[
        {
          required: true,
          message: "Bu maydonni to'ldirish shart",
        },
      ]}
    >
      <Upload
    className="avatar-uploader"
    name={"image"}
    listType="picture"
    customRequest={({ file, onSuccess }) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    }}
    // beforeUpload={beforeUploadExcel}
    accept={".jpg, .png, .jpeg, .PNG"}
    onChange={handleChange}
  >
    <img
                          src={imageUrl}
                          alt="avatar"
                          style={{
                            width: "70px",
                            height: "70px",
                            marginLeft:"10px"
                          }}
                        />   
                        <Button
          style={{
            width: "100%",
            marginTop:"20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          icon={<UploadOutlined />}
        >
          Tanlash
        </Button>
                        
    
      
                      
  </Upload>
                        
    </Form.Item>
                </Col>
               
              </Row>
              <Row style={{ paddingTop: "20px",marginBottom:"10px" }}>
                <Col style={{ padding: "0px 20px" }} lg={24} md={24}>
                  <InputDesc name="description" label={"Sayt matni"}/>
                </Col>
              </Row>
                
              
            </Form>
          
      </div>
      <Modal
        title=""
        footer={false}
        open={modal}
        onOk={() => {
          setModal(false);
        }}
        onCancel={() => {
          setModal(false);
        }}
      >
        <div>
          <b style={{ fontSize: "18px" }}>Sayt matni</b>
          <p
            style={{ fontSize: "16px" }}
            dangerouslySetInnerHTML={{
              __html: sel_data !== null ? sel_data.description : "",
            }}
          />
        </div>
      </Modal>
    </div>
  );
}
